import { Card, Container, Form } from 'react-bootstrap';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AjaxPhp, UpdateEntityRequestBody } from '@property-folders/common/util/ajaxPhp';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { BareAddressSelector } from '@property-folders/components/dragged-components/address/BareAddressSelector';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { FullPageLoadingSpinner } from '@property-folders/components/dragged-components/FullPageLoadingSpinner';
import { AddressPartsSchema, Maybe } from '@property-folders/contract';
import { AuthApi } from '@property-folders/common/client-api/auth';
import {
  validateChange,
  ValidationNode
} from '@property-folders/common/yjs-schema/property/validation/process-validation';
import { isEqual } from 'lodash';
import { canonicalisers } from '@property-folders/common/util/formatting';

type DataProperty = keyof UpdateEntityRequestBody;
type CanonicalMethod =
  | 'email'
  | 'phone'
  | 'bn'
  | 'nzbn'
  | 'abnacn';

export function AgencyDetails() {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const { data: session } = AuthApi.useGetAgentSessionInfo();

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [init, setInitData] = useState<Maybe<UpdateEntityRequestBody>>(undefined);
  const [latest, setLatestData] = useState<Maybe<UpdateEntityRequestBody>>(undefined);
  const unchanged = useMemo(() => {
    return isEqual(init, latest);
  }, [init, latest]);
  const effectiveCountry = latest?.country || 'Australia';
  const isNewZealand = effectiveCountry === 'New Zealand';
  const isGa = Boolean(session?.isGlobalAdmin);

  useEffect(() => {
    const entityId = entityPhpInfo?.entityId;
    if (!entityId) return;

    AjaxPhp.loadEntity({ entityId }).then(result => {
      if (result === undefined) {
        return;
      }

      const data: UpdateEntityRequestBody = {
        ...result,
        gnafId: result.gnafId ?? ''
      };
      setInitData(structuredClone(data));
      setLatestData(structuredClone(data));
    })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [entityPhpInfo?.entityId]);

  const validationResult = useMemo(() => validate(latest), [latest]);
  const valid = validationResult._validationResult.valid;
  if (!valid && !loading) {
    // frontend debugging
    console.error('validationResult', validationResult);
  }

  const onSave = () => {
    if (loading) return;
    if (!latest) return;
    const entityId = entityPhpInfo?.entityId;
    if (!entityId) return;

    setSaving(true);
    setErrorMessage('');
    const clone = structuredClone(latest);
    AjaxPhp.updateEntity(entityId, clone)
      .then(() => {
        setInitData(clone);
      })
      .catch(err => {
        console.error(err);
        setErrorMessage('There was an error saving changes. Please try again.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  function getValidationErrors(...path: string[]) {
    let current = validationResult;
    for (const item of path) {
      current = current?.[item];
    }
    return (current as ValidationNode | undefined)
      ?._validationResult
      ?.errors || [];
  }

  const setLatestDataField = (key: DataProperty, value: string) => {
    setLatestData(cur => {
      if (!cur) return cur;
      const clone = structuredClone(cur);
      // @ts-ignore
      clone[key] = value;
      return clone;
    });
  };

  const mutateLatestData = (mutator: (draft: UpdateEntityRequestBody) => void) => {
    setLatestData(cur => {
      if (!cur) return cur;
      const clone = structuredClone(cur);
      mutator(clone);
      return clone;
    });
  };

  const canonicaliseField = (key: DataProperty, canon: CanonicalMethod) => {
    mutateLatestData(draft => {
      const result = (() => {
        switch (canon) {
          case 'bn':
            return canonicalisers.bn(draft[key]);
          case 'abnacn':
            return canonicalisers.abnacn(draft[key]);
          case 'nzbn':
            return canonicalisers.nzbn(draft[key]);
          case 'phone':
            return canonicalisers.phone(draft[key], { alwaysCountryPrefix: true });
          case 'email':
            return canonicalisers.email(draft[key]);
        }
      })();
      if (result.valid) {
        draft[key] = result.canonical.toString();
      }
    });
  };

  function makeText(
    label: string,
    type: string,
    name: DataProperty,
    opts?: {
      canon?: CanonicalMethod,
      gaOnly?: boolean
    }
  ) {
    if (!latest) return <></>;

    const errors = getValidationErrors(name);

    return <Form.FloatingLabel label={label} className='common-label'>
      <Form.Control
        type={type}
        name={name}
        placeholder=''
        value={latest[name]}
        onChange={e => {
          setLatestDataField(
            name,
            e.currentTarget.value);
        }}
        onBlur={() => {
          if (!opts?.canon) return;
          canonicaliseField(name, opts.canon);
        }}
        isInvalid={Boolean(errors.length)}
        readOnly={Boolean(opts?.gaOnly && !isGa)}
        disabled={Boolean(opts?.gaOnly && !isGa)}
      />
      <ErrorLabel label={label || ''} errors={errors}/>
    </Form.FloatingLabel>;
  }

  if (loading || !init || !latest) {
    return <FullPageLoadingSpinner lightMode={true}/>;
  }

  return <Container fluid={false} className='h-100 position-relative d-flex flex-column flex-start g-0'>
    <Card>
      <Card.Header>
        <div className='title d-flex w-100 align-items-center justify-content-between'>
          <span className='fs-3'>Company Details</span>
          <div className='d-flex flex-column align-items-end'>
            <SpinnerButton disabled={unchanged || !valid} processing={saving} onClick={onSave}>Save</SpinnerButton>
            {errorMessage && <span className='text-danger'>{errorMessage}</span>}
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Form className='row'>
          <Form.Group className='mb-3 col-12 col-md-6'>
            {makeText('Company Name', 'text', 'companyName', { gaOnly: true })}
          </Form.Group>
          <Form.Group className='mb-3 col-12 col-md-6'>
            {makeText('Trading Name', 'text', 'tradingName', { gaOnly: true })}
          </Form.Group>

          <Form.Group className='mb-3 col-12 col-md-4'>
            <Form.FloatingLabel label='Country' className='common-label'>
              <Form.Select
                placeholder='Australia'
                defaultValue={effectiveCountry}
                onChange={e => {
                  const value = e.target.value;
                  mutateLatestData(draft => {
                    draft.country = value;
                    draft.abn = '';
                  });
                }}
              >
                <option value='Australia'>Australia</option>
                <option value='New Zealand'>New Zealand</option>
              </Form.Select>
            </Form.FloatingLabel>
          </Form.Group>

          <Form.Group className='mb-3 col-12 col-md-4'>
            {makeText(isNewZealand ? 'NZBN' : 'ABN/ACN', 'text', 'abn', { canon: isNewZealand ? 'nzbn' : 'abnacn', gaOnly: true })}
          </Form.Group>
          <Form.Group className='mb-3 col-12 col-md-4'>
            {makeText('Agent Licence Number', 'text', 'rla')}
          </Form.Group>

          <div className='col-12 col-md-6'>
            <BareAddressSelector
              id='entity-address'
              label='Address'
              defaultValue={latest.address1}
              onAddressSelect={(value, partsRaw, gnaf) => {
                const parseResult = AddressPartsSchema.safeParse(partsRaw);
                if (parseResult.success) {
                  const parts = parseResult.data;
                  mutateLatestData(draft => {
                    draft.address1 = value;
                    draft.suburb = parts.Suburb || '';
                    draft.state = parts.State || '';
                    draft.postCode = parts.Postcode || '';
                    draft.gnafId = gnaf || '';
                  });
                } else {
                  mutateLatestData(draft => {
                    draft.address1 = value;
                    draft.suburb = '';
                    draft.state = '';
                    draft.postCode = '';
                    draft.gnafId = gnaf || '';
                  });
                }
              }}
              placeholder='123 Main Street'
              countries={['Australia', 'New Zealand']}
              international={effectiveCountry !== 'Australia'}
            />
          </div>

          {effectiveCountry === 'Australia'
            ? <>
              <Form.Group className='mb-3 col-12 col-md-2'>
                {makeText('Suburb', 'text', 'suburb')}
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-2'>
                {makeText('State', 'text', 'state')}
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-2'>
                {makeText('Post Code', 'text', 'postCode')}
              </Form.Group>
            </>
            : <>
              <Form.Group className='mb-3 col-12 col-md-3'>
                {makeText('Suburb', 'text', 'suburb')}
              </Form.Group>
              <Form.Group className='mb-3 col-12 col-md-3'>
                {makeText('Post Code', 'text', 'postCode')}
              </Form.Group>
            </>}

          <Form.Group className='mb-3 col-12 col-md-3'>
            {makeText('Office Phone', 'text', 'phone', { canon: 'phone' })}
          </Form.Group>
          <Form.Group className='mb-3 col-12 col-md-3'>
            {makeText('Office Fax', 'text', 'fax', { canon: 'phone' })}
          </Form.Group>

          <Form.Group className='mb-3 col-12 col-md-6'>
            {makeText('Office Email', 'text', 'email', { canon: 'email' })}
          </Form.Group>
          <Form.Group className='mb-3 col-12 col-md-6'>
            {makeText('Billing Email', 'text', 'billingEmail', { canon: 'email' })}
          </Form.Group>

          {session?.isGlobalAdmin && <>
            <hr/>
            <h5>Global Admin Only</h5>
            {Boolean(session?.isGlobalAdmin) && <Form.Group className='mb-3 col-12 col-md-6'>
              <Form.FloatingLabel label='Time Zone' className='common-label'>
                <Form.Select
                  placeholder='Australia/Adelaide'
                  value={latest.timeZone}
                  onChange={e => setLatestDataField('timeZone', e.target.value)}
                >
                  <option value='Australia/Adelaide'>Australia/Adelaide</option>
                  <option value='Australia/Brisbane'>Australia/Brisbane</option>
                  <option value='Australia/Broken_Hill'>Australia/Broken_Hill</option>
                  <option value='Australia/Darwin'>Australia/Darwin</option>
                  <option value='Australia/Eucla'>Australia/Eucla</option>
                  <option value='Australia/Hobart'>Australia/Hobart</option>
                  <option value='Australia/Lindeman'>Australia/Lindeman</option>
                  <option value='Australia/Lord_Howe'>Australia/Lord_Howe</option>
                  <option value='Australia/Melbourne'>Australia/Melbourne</option>
                  <option value='Australia/Perth'>Australia/Perth</option>
                  <option value='Australia/Sydney'>Australia/Sydney</option>
                  <option value='Pacific/Auckland'>Pacific/Auckland</option>
                  <option value='Pacific/Chatham'>Pacific/Chatham</option>
                </Form.Select>
              </Form.FloatingLabel>
            </Form.Group>}
            <Form.Group className='mb-3 col-12 col-md-6'>
              {makeText('Business (Our reference only)', 'text', 'business')}
            </Form.Group>
            <Form.Group className='mb-3 col-12'>
              {makeText('Address Display', 'text', 'addressDisplay')}
            </Form.Group>
          </>}
        </Form>
      </Card.Body>
    </Card>
  </Container>;
}

function validate(data: UpdateEntityRequestBody | undefined) {
  return validateChange(
    data || {},
    {
      _type: 'Map',
      companyName: { _type: 'string', _required: true },
      tradingName: { _type: 'string' },
      business: { _type: 'string' },
      address1: { _type: 'string' },
      addressDisplay: { _type: 'string' },
      suburb: { _type: 'string' },
      postCode: { _type: 'string' },
      state: { _type: 'string' },
      country: { _type: 'string' },
      abn: { _type: 'string', _subtype: data?.country === 'New Zealand' ? 'nzbn' : 'abnacn' },
      rla: { _type: 'string' },
      phone: { _type: 'string', _subtype: 'phone' },
      fax: { _type: 'string', _subtype: 'phone' },
      email: { _type: 'string', _subtype: 'email' },
      billingEmail: { _type: 'string', _subtype: 'email' },
      timeZone: { _type: 'string' }
    },
    // further validation rules here
    {},
    {},
    []
  );
}

function ErrorLabel({ label: labelRaw, errors, message: messageOverride }: {
  label?: string,
  errors: string[],
  message?: string
}) {
  const first = errors.at(0);
  if (!first) return <></>;

  const label = labelRaw || 'This';
  const message = (() => {
    if (messageOverride) return messageOverride;
    switch (first) {
      case 'required':
        return `${label} is required.`;
      case 'abnacn':
        return `${label} must be a valid ABN / ACN.`;
      case 'nzbn':
        return `${label} must be a valid NZBN.`;
      case 'bn':
        return `${label} must be a valid Business or Company Number`;
      case 'email':
        return `${label} must be a valid email address.`;
      case 'phone':
        return `${label} must be a valid phone number.`;
      default:
        return `${first} unhandled error type.`;
    }
  })();

  return <small className='text-danger'>{message}</small>;
}
