import { Button, Modal } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsJn from '@property-folders/common/util/classNameJoin';
import { ManifestType, Maybe } from '@property-folders/contract';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { EntityFileType, FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { useStore } from 'react-redux';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import * as hash from 'object-hash';

export function SettingsImageUpload({
  label,
  fileType,
  parentId,
  showPreview,
  onUpload,
  onRemove,
  labelClass
}: {
  label?: string,
  fileType: EntityFileType,
  parentId: string,
  showPreview?: boolean,
  onUpload?: (fileId: string, contentType: string) => void,
  onRemove?: () => void,
  labelClass?: string
}) {

  const { entityUuid } = useContext(EntitySettingsContext);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [newImage, setNewImage] = useState<Maybe<{ filename: string, data: Blob, objectUrl: string, type: string }>>(undefined);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const store = useStore();
  const { instance: fileSync } = useContext(FileSyncContext);

  const handleDrop = async (files: File[]) => {
    if (files.length !== 1) return;
    const file = files[0];
    setNewImage({
      filename: file.name,
      data: file,
      type: file.type,
      objectUrl: URL.createObjectURL(file)
    });
  };
  const { getRootProps, getInputProps, inputRef, isDragAccept } = useDropzone({
    onDrop: (files) => handleDrop(files),
    noClick: true,
    multiple: false,
    accept: { 'image/jpeg': [], 'image/png': [] }
  });
  const openUploadModal = () => {
    setNewImage(undefined);
    setShowUploadModal(true);
    setProcessing(false);
  };
  const closeUploadModal = () => {
    setNewImage(undefined);
    setShowUploadModal(false);
    setProcessing(false);
  };

  const md5ToUuid = (md5Hash:string) => {
    return (
      md5Hash.substring(0, 8) +
      '-' +
      md5Hash.substring(8, 12) +
      '-' +
      md5Hash.substring(12, 16) +
      '-' +
      md5Hash.substring(16, 20) +
      '-' +
      md5Hash.substring(20)
    ).toLowerCase();
  };

  const doUpload = async () => {
    if (!newImage) return;
    if (!entityUuid) return;
    setProcessing(true);
    try {
      const fileHash = hash.MD5((await newImage.data.text())+entityUuid);
      const fileId = md5ToUuid(fileHash);

      await FileStorage.write(
        fileId,
        FileType.EntityFile,
        newImage.type,
        new Blob([newImage.data], { type: newImage.type }),
        StorageItemSyncStatus.PendingUpload,
        {
          entityFile: {
            entityUuid,
            fileType,
            parentId
          }
        },
        store,
        {
          manifestType: ManifestType.None
        },
        undefined,
        undefined
      );

      FileSync.triggerSync(fileSync);
      onUpload?.(fileId, newImage.type);
      closeUploadModal();

    } catch (err: unknown) {
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  const doRemove = async () => {
    setProcessing(true);
    try {
      onRemove?.();
      setShowRemoveModal(false);
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  return <>
    {label && <div className={clsJn('lead', labelClass)}>
      <span>{label}</span>
    </div>}
    <div className='d-flex flex-column'>
      <div className='mt-1 d-flex flex-row align-items-end gap-2'>
        <Button variant='outline-primary' onClick={openUploadModal}>Upload</Button>
        <Button variant='outline-danger' onClick={() => setShowRemoveModal(true)}>Remove</Button>
      </div>
      {showPreview && <div className='w-100 mt-2 d-flex justify-content-center align-items-center border border-1 p-2'>
        {newImage
          ? <img src={newImage.objectUrl} style={{ height: '64px', objectFit: 'contain' }}></img>
          : <div className='fs-4 d-flex justify-content-center align-items-center' style={{ height: '64px' }}>None Set</div>}
      </div>}
    </div>
    {showUploadModal && <Modal show={showUploadModal} onHide={() => setShowUploadModal(false)} backdrop={processing ? 'static' : true}>
      <Modal.Header>
        <Modal.Title>Upload {label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div {...getRootProps({
          className: clsJn(
            'd-flex flex-row align-items-center justify-content-center cursor-pointer drop-target',
            isDragAccept && 'drop-accept'
          ),
          style: {
            height: '100px'
          },
          onClick: () => {
            inputRef?.current?.click();
          }
        })}>
          <input {...getInputProps({ className: 'd-none', accept: 'image/jpeg,image/png' })}/>
          {newImage
            ? <div className='d-flex flex-row flex-nowrap gap-2 align-items-center justify-content-center h-100 w-100 p-2'>
              <img src={newImage.objectUrl} className='h-100' style={{ objectFit: 'contain' }} />
              <div className='d-flex flex-row align-items-center overflow-hidden wrap-text'><h5>{newImage.filename}</h5></div>
            </div>
            : <h5>Choose an image...</h5>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={closeUploadModal}>Cancel</Button>
        <SpinnerButton disabled={!newImage} processing={processing} onClick={doUpload}>Upload</SpinnerButton>
      </Modal.Footer>
    </Modal>}
    {showRemoveModal && <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)} backdrop={processing ? 'static' : true}>
      <Modal.Header>
        <Modal.Title>Remove {label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure?</Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setShowRemoveModal(false)}>Cancel</Button>
        <SpinnerButton variant='danger' disabled={!!newImage} processing={processing} onClick={doRemove}>Remove</SpinnerButton>
      </Modal.Footer>
    </Modal>}
  </>;
}
