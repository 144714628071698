import { Alert, Button, Col, Form, Image, InputGroup, Modal, Row, Spinner, ToggleButton } from 'react-bootstrap';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { AwarenessData, MaterialisedPropertyData, OfferMeta, ProspectivePurchaserParty, PurchaserProcessAction, PurchaserProcessMeta, PurchaserProcessStepType } from '@property-folders/contract';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  InfiniteScrollListColumn,
  LazyInfiniteTableList
} from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import { ProcessEditor, ProcessStep, PurchaserProcessStep } from '@property-folders/components/dragged-components/ProcessEditor';
import { FormCode, FormInstance, META_APPEND, PropertyRootKey, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { orderBy } from 'lodash';
import './OfferManagement.scss';
import { canonicalisers, formatTimestamp } from '@property-folders/common/util/formatting';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { insertIf } from '@property-folders/common/util/pdfgen';
import { formatPartyDetails, mapStepsToSettings } from '~/pages/ProspectivePurchasersPage';
import { RouterData } from '~/App';
import { Y } from '@syncedstore/core';
import { PurchaserSubmittedDocumentStatus, SettingsOfferType } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { OfferType, OfferValues } from '@property-folders/contract/property/OfferContractState';
import { Predicate } from '@property-folders/common/predicate';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { PurchaserApi } from '@property-folders/common/client-api/purchaserApi';
import { CustomiseMessageStandalone, LimitedCharacterTextField } from '@property-folders/components/dragged-components/signing/CustomiseMessage';
import { reaformsOrange } from '@property-folders/common/visual';
import { useEntityLogo } from '@property-folders/components/hooks/useEntityLogo';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useSelector } from 'react-redux';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { MaterialisedPropertyMetaRecord } from '@property-folders/services/lib/dal/ddb/property-transaction/db-types';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { ShortId } from '@property-folders/common/util/url';
import { compareFormInstances } from '@property-folders/common/util/compareFormInstances';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import clsJn from '@property-folders/common/util/classNameJoin';
import { initialPresence } from '~/pages/TransactionHomePage';
import { RoomProvider } from '@y-presence/react';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { cloneContractLikeFactory } from '@property-folders/components/form-gen-util/clone';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { handleCreateContractFromOfferFactory } from '@property-folders/components/form-gen-util/create-contract';
import { useGetPurchaserSettingsQuery, useUpdatePurchaserSettingsMutation } from '@property-folders/common/redux-reducers/restApiSlice';
import '@property-folders/components/display/EmailPreview.scss';

function handleLetterOfOfferSublineageConversion(ydoc: Y.Doc, offerId: string, offerRowData: {data: MaterialisedPropertyData, meta: TransactionMetaData}) {
  const dataKey = offerId;
  const metaKey = offerId + META_APPEND;

  applyMigrationsV2_1({
    typeName: 'Property',
    doc: ydoc,
    docKey: metaKey,
    migrations: [
      {
        name: 'Copy relevant metadata',
        fn: (draft: TransactionMetaData) => {
          Object.assign(draft, offerRowData.meta);
        }
      },
      {
        name: 'Copy data',
        docKey: dataKey,
        fn: (draft: MaterialisedPropertyData) => {
          Object.assign(draft, offerRowData.data);
        }
      },
      {
        name: 'Declare sublineage',
        docKey: PropertyRootKey.Meta,
        fn: (draft: TransactionMetaData) => {
          if (!draft.sublineageRoots) draft.sublineageRoots = [];
          draft.sublineageRoots?.push(offerId);
        }
      }
    ]
  });
}

export function InnerPage() {
  const routerData = useReactRouterData<RouterData>();
  const { ydoc, docName } = useContext(YjsDocContext);
  const { instance: fileSync } = useContext(FileSyncContext);
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ });
  const { value: offerManagement } = useLightweightTransaction<OfferMeta>({ myPath: 'offerManagement', bindToMetaKey: true });
  const { updateDraft: updateOffers } = useYdocBinder<OfferMeta>({ path: 'offerManagement', bindToMetaKey: true  });
  const { value: propertyMeta } = useLightweightTransaction<TransactionMetaData>({ myPath: '', bindToMetaKey: true });
  const navigate = useNavigate();
  const headline = generateHeadlineFromMaterialisedData(transRoot);
  const [filterStatus, setFilterStatus] = useState<string>();
  const [filter, setFilter] = useState<string>();
  const [showStarred, setShowStarred] = useState<boolean>(false);
  const [offerActionModal, setOfferActionModal] = useState<{ visible: boolean, type?: OfferActionModalType, offerId?: string }>({ visible: false });

  const { purchaserIdRaw } = useParams();
  const purchaserId = purchaserIdRaw
    ? ShortId.toUuid(purchaserIdRaw)
    : undefined;

  const columnDefinition = useMemo<InfiniteScrollListColumn<OfferRow>[]>(() => [
    {
      rowMajor: (rowData: OfferRow) => <Icon icoClass={clsJn('star-button', rowData.isFavourite && 'starred')} name={'star'} onClick={(e)=>handleOfferFavouriteClick(e, rowData.id)}></Icon>,
      headerCellStyle: { width: '25px' },
      onClick: (e: React.SyntheticEvent) => e.stopPropagation(),
      sort: {
        key: 'isFavourite'
      }
    },
    {
      label: 'Primary Purchaser',
      rowMajor: (rowData: OfferRow) => formatPartyDetails(rowData?.purchasers?.find(pp => pp.id === rowData.primaryPurchaser)),
      sort: {
        key: 'primaryPurchaser',
        sortFn: (rowData: OfferRow) => rowData?.purchasers?.find(pp => pp.id === rowData.primaryPurchaser)?.fullLegalName
      }
    },
    {
      label: 'Other Purchasers',
      rowMajor: (rowData: OfferRow) => <div className={'d-flex gap-4'}>
        {rowData?.purchasers?.slice(1)?.map(p => formatPartyDetails(p))}
      </div>,
      headerCellStyle: { width: '25%' }
    },
    {
      label: 'Amount',
      rowMajor: (rowData: OfferRow) => canonicalisers.aud(rowData?.offerAmount)?.display,
      rowMinor: (rowData: OfferRow) => canonicalisers.aud(rowData?.offerDeposit)?.display,
      headerCellStyle: { width: '12%' },
      sort: {
        key: 'offerAmount'
      }
    },
    {
      label: 'Received',
      rowMajor: (rowData: OfferRow) => formatTimestamp(rowData?.offerReceivedDate, '', false),
      headerCellStyle: { width: '12%' },
      sort: {
        key: 'offerReceivedDate',
        defaultSort: 'desc',
        sortFn: (rowData: OfferRow) => rowData.offerReceivedDate ? new Date(rowData.offerReceivedDate) : ''
      }
    },
    {
      label: 'Conditions',
      rowMinor: (rowData: OfferRow) => {
        return <>
          {rowData.offerConditions?.finance && <div className='contract-conditions'>Finance</div>}
          {rowData.offerConditions?.salePurchaserProperty && <div className='contract-conditions'>Sale of Property</div>}
        </>;
      },
      headerCellStyle: { width: '12%' },
      sort: {
        key: 'offerConditions',
        sortFn: (rowData: OfferRow) => +(rowData.offerConditions?.salePurchaserProperty??0) + +(rowData.offerConditions?.finance??0)
      }
    },
    {
      label: 'Settlement',
      rowMajor: (rowData: OfferRow) => formatTimestamp(rowData?.offerSettlementDate, '', false),
      headerCellStyle: { width: '12%' },
      sort: {
        key: 'offerSettlementDate',
        sortFn: (rowData: OfferRow) => rowData.offerSettlementDate ? new Date(rowData.offerSettlementDate) : ''
      }
    },
    {
      label: 'Status',
      rowMajor: (rowData: OfferRow) => rowData?.offerStatus,
      rowMinor: (rowData: OfferRow) => formatTimestamp(rowData?.offerStatusDate, '', false),
      headerCellStyle: { width: '12%' },
      sort: {
        key: 'offerStatus'
      }
    }
  ], []);

  type OfferRow = {
    id: string,
    purchasers: ProspectivePurchaserParty[],
    primaryPurchaser: string,
    offerAmount: number,
    offerDeposit: number,
    offerReceivedDate: string,
    offerConditions: OfferValues['conditions'],
    offerSettlementDate: string,
    offerStatus: PurchaserSubmittedDocumentStatus,
    offerStatusDate: number,
    type: OfferType,
    data: MaterialisedPropertyData,
    meta: TransactionMetaData,
    isFavourite: boolean
  };

  const flattenedOffers = offerManagement?.prospectivePurchasers
    ?.filter(p=> purchaserId ? p.id === purchaserId : true)
    ?.flatMap(p => (p.submittedOffers?.map(o => ({
      id: o.id,
      purchasers: p.purchaserParties,
      primaryPurchaser: p.primaryPurchaser,
      offerAmount: o.offerSummary?.offerPrice,
      offerDeposit: o.offerSummary?.deposit,
      offerReceivedDate: o.purchaserSigning?.[0]?.signedAt,
      offerConditions: o.offerSummary?.conditions,
      offerSettlementDate: o.offerSummary?.settlementDate,
      offerStatus: o.status,
      offerStatusDate: o.statusAtMs,
      type: o.type,
      data: o.data,
      meta: o.metadata,
      isFavourite: o.isFavourite
    })))).filter(Boolean) as OfferRow[];

  const handleOfferFavouriteClick = (evt: React.SyntheticEvent, offerId: string) => {
    updateOffers?.(draft => {
      const offer = draft.prospectivePurchasers?.flatMap(pp => pp.submittedOffers)?.find(o => o.id === offerId);
      if (!offer) return;
      offer.isFavourite = !offer.isFavourite;
    });
    evt.stopPropagation();
    evt.preventDefault();
  };

  function getLatestFormInstanceForSublineage (lineageRootKey: string, formCode: FormCode = FormCode.RSC_ContractOfSale) {
    const formStates = (ydoc.getMap(lineageRootKey+META_APPEND).toJSON() as TransactionMetaData).formStates;
    const formState = formStates?.[formCode];
    const lineageInstances = formState?.instances||[] as FormInstance[];
    return [...lineageInstances].sort(compareFormInstances)[0];
  }

  function handleVendorToSign (lineageRootKey: string, data: MaterialisedPropertyData, purchasers: ProspectivePurchaserParty[]) {
    const latestInstance = getLatestFormInstanceForSublineage(lineageRootKey);
    const firstPurchaserName = purchasers?.[0]?.fullLegalName;
    if (!latestInstance || !ydoc || !docName || !firstPurchaserName) return;
    navigate(LinkBuilder.vendorToSignPath({ id: docName, nicetext: generateHeadlineFromMaterialisedData(data) }, { id: latestInstance.id, nicetext: firstPurchaserName }));
  }

  function handleViewOfferContract (lineageRootKey: string, data: MaterialisedPropertyData) {
    const instance = getLatestFormInstanceForSublineage(lineageRootKey);
    if (!instance) return;
    navigate(LinkBuilder.documentPath(
      { id: docName, nicetext: generateHeadlineFromMaterialisedData(data) },
      { id: instance.id, nicetext: FormTypes[instance.formCode].label },
      false)
    );
  }

  const handlePreviewOffer = async (offer: OfferRow) => {
    if (!ydoc) return;
    if (!propertyMeta?.sublineageRoots?.includes(offer.id)) {
      handleLetterOfOfferSublineageConversion(ydoc, offer.id, offer);
    }
    const instance = getLatestFormInstanceForSublineage(offer.id, FormCode.OfferToPurchase);
    if (!instance) return;
    navigate(LinkBuilder.documentPath(
      { id: docName, nicetext: generateHeadlineFromMaterialisedData(offer.data) },
      { id: instance.id, nicetext: FormTypes[instance.formCode].label },
      false)
    );
  };

  const rowActions = [
    {
      label: 'Vendor to Sign',
      action: (rowData: OfferRow) => handleVendorToSign(rowData.id, rowData.data, rowData.purchasers),
      if: (rowData: OfferRow) => rowData.offerStatus === PurchaserSubmittedDocumentStatus.Submitted && rowData.type === OfferType.OfferContract

    },
    {
      label: 'View',
      action: (rowData: OfferRow) => rowData.type === OfferType.OfferContract ? handleViewOfferContract(rowData.id, rowData.data) : handlePreviewOffer(rowData)
    },
    {
      label: 'Decline',
      action: (rowData: OfferRow) => setOfferActionModal({ visible: true, type: OfferActionModalType.decline, offerId: rowData.id }),
      if: (rowData: OfferRow) => rowData.offerStatus === PurchaserSubmittedDocumentStatus.Submitted,
      disableIfOffline: true
    },
    {
      label: 'Withdraw',
      action: (rowData: OfferRow) => setOfferActionModal({ visible: true, type: OfferActionModalType.withdraw, offerId: rowData.id }),
      if: (rowData: OfferRow) => rowData.offerStatus === PurchaserSubmittedDocumentStatus.Submitted,
      disableIfOffline: true
    },
    {
      label: 'Resubmit',
      action: (rowData: OfferRow) => setOfferActionModal({ visible: true, type: OfferActionModalType.resubmit, offerId: rowData.id }),
      if: (rowData: OfferRow) => rowData.offerStatus === PurchaserSubmittedDocumentStatus.Withdrawn,
      disableIfOffline: true
    },
    {
      label: 'Accept',
      action: (rowData: OfferRow) => setOfferActionModal({ visible: true, type: OfferActionModalType.accept, offerId: rowData.id }),
      if: (rowData: OfferRow) => rowData.offerStatus === PurchaserSubmittedDocumentStatus.Submitted && rowData.type === OfferType.LetterOfOffer,
      disableIfOffline: true
    },
    {
      label: 'Create Contract',
      action: (rowData: OfferRow) => rowData.type === OfferType.LetterOfOffer ? handleCreateContractFromOffer(rowData.data) : handleCloneOfferedContract(rowData.id),
      if: (rowData: OfferRow) => rowData.offerStatus === PurchaserSubmittedDocumentStatus.Submitted
    }
  ];

  const favouriteFilteredOffers = flattenedOffers?.filter(o => !showStarred || o.isFavourite);
  const statusFilteredOffers= filterStatus ? favouriteFilteredOffers.filter(o => o?.offerStatus === filterStatus) : favouriteFilteredOffers;
  const filteredOffers= filter ? statusFilteredOffers.filter(p=> p.purchasers.map(p => [
    p.fullLegalName?.toLowerCase(),
    p.email?.toLowerCase(),
    p.phone?.toLowerCase(),
    p.phone?.replace('+61', '0')?.toLowerCase()
  ].filter(Predicate.isNotNull).join(',')).join(',').includes(filter.toLowerCase())) : statusFilteredOffers;
  const sortedOffers = orderBy(filteredOffers, p=> p?.offerAmount, 'desc');
  const purchaser = offerManagement?.prospectivePurchasers?.find(p => p.id === purchaserId);
  const primaryPurchaser = purchaser?.purchaserParties?.find(pp => pp.id === purchaser.primaryPurchaser);

  const handleCloneOfferedContract = cloneContractLikeFactory({ navigate, ydoc, fileSync, propertyId: routerData.transId, headline });

  const handleCreateContractFromOffer = handleCreateContractFromOfferFactory({ fileSync, headline, navigate, propertyId: routerData.transId, ydoc: routerData.ydoc });

  const handleOfferAction = (email: { subject: string, message: string } | undefined) => {
    setOfferActionModal(p =>({ ...p, visible: false }));
    if (!offerActionModal.offerId) return;

    switch (offerActionModal.type) {
      case OfferActionModalType.decline:
        PurchaserApi.declineOffer(routerData.transId, offerActionModal.offerId, email);
        break;

      case OfferActionModalType.withdraw:
        PurchaserApi.withdrawOffer(routerData.transId, offerActionModal.offerId, email);
        break;

      case OfferActionModalType.resubmit:
        PurchaserApi.resubmitOffer(routerData.transId, offerActionModal.offerId, email);
        break;

      case OfferActionModalType.accept:
        PurchaserApi.acceptOffer(routerData.transId, offerActionModal.offerId, email);
        break;
    }
  };

  const getLatestAction = (actions: PurchaserProcessMeta[] | undefined) => {
    return orderBy(actions, a => a.date, 'desc')?.[0];
  };

  const latestActions = {
    registration: getLatestAction(purchaser?.meta?.registration),
    form1: getLatestAction(purchaser?.meta?.form1),
    exampleContract: getLatestAction(purchaser?.meta?.exampleContract),
    offerDocument: getLatestAction(purchaser?.meta?.offerDocument)
  };

  const breadcrumbs = useMemo(() => [
    { label: 'Properties', href: '/properties/' },
    { label: headline || 'Property Overview', href: `/properties/${LinkBuilder.seoFriendlySlug(routerData.transId, headline)}` },
    ...insertIf(purchaserId, { label: 'Offers', href: LinkBuilder.propertyPagePath({ id: routerData.transId, nicetext: headline }, 'offer-management') }),
    { label: '' }
  ], [headline]);

  const afterTitle = <>
    <Button variant="primary" disabled={true}>Add Offer</Button>
  </>;

  return <ContentTitler title={purchaserId ? `${primaryPurchaser?.fullLegalName}'s Offers` : 'Offers'} breadcrumbs={breadcrumbs} afterTitle={afterTitle} flex={true} scroll={false}>
    <Row className={'px-3 mt-1 mb-2 d-flex flex-row g-0'}>
      <Col lg={7} sm={12}>
        {purchaserId && <PurchaserProcess purchaserId={purchaserId} purchaserFor={primaryPurchaser?.fullLegalName}/>}
      </Col>
      <Col lg={5} sm={12} className={'mt-1'}>
        {(!!flattenedOffers?.length) && <div className={'d-flex flex-column flex-lg-row gap-2 justify-content-end'}>
          {!purchaserId &&
            <InputGroup className={'embed-icon-wrapper'}>
              <Form.Control
                value={filter}
                onChange={(event) => setFilter(event.target.value)}
                placeholder="Name, email, or phone..."
                className={'embed-icon-target'}
              />
              <Icon name={'search'} variant={'outlined'} icoClass={'embed-icon'} style={{ marginTop: '-3px' }}></Icon>
              <ToggleButton
                type={'checkbox'}
                value={'show-starred'}
                variant={'outline-secondary'}
                checked={showStarred}
                onClick={() => setShowStarred(!showStarred)}
                title='Show starred offers only'
                className={'search d-flex align-items-center justify-content-center'}

              >
                <Icon name={showStarred ? 'star' : 'star_outline'}></Icon>
              </ToggleButton>
            </InputGroup>
          }

          <Form.Select style={{ maxWidth: '300px' }} onChange={e => setFilterStatus(e.target.value)}>
            <option value={''}>Any Status</option>
            {['Submitted', 'Withdrawn', 'Declined'].map(s => <option value={s}>{s}</option>)}
          </Form.Select>
        </div>
        }
      </Col>
    </Row>

    {flattenedOffers?.length || !purchaserId
      ? <Row className={'g-0 flex-grow-1 flex-shrink-1 overflow-hidden'}>
        <LazyInfiniteTableList
          storageKey={'OfferManagement'}
          hasNextPage={false}
          items={sortedOffers.map(o => ({ ...o, rowClass: [PurchaserSubmittedDocumentStatus.Withdrawn, PurchaserSubmittedDocumentStatus.Declined].includes(o?.offerStatus) ? 'row-disabled' : '' }))}
          columns={columnDefinition}
          rowActions={rowActions}
          rowClick={rowData=> rowData.type === OfferType.OfferContract ? handleViewOfferContract(rowData.id, rowData.data) : handlePreviewOffer(rowData)}
          containerClass={'mw-100 mt-0'}
          headerClass={'offer-table-header'}
          rowHeight={'68px'}
        />
      </Row>
      : !!purchaser && <Row className={'px-3 mt-4 d-flex flex-row'}>
        <Col lg={12}>
          <Alert variant={'info'}>
            <div className={'d-flex flex-column align-items-center pb-2'}>
              <div className={'fs-4'}>{`When ${primaryPurchaser?.fullLegalName} creates an offer, you will see it here.`}</div>
              <div className={'d-flex flex-row mt-3 flex-wrap gap-1'}>
                <PurchaserStatusPanel
                  icon={latestActions.registration?.action === PurchaserProcessAction.registered ? 'check_circle' : 'pending'}
                  title={latestActions.registration?.action === PurchaserProcessAction.registered
                    ? 'Registered' : latestActions.registration?.action === PurchaserProcessAction.unverified
                      ? 'Unverified' : latestActions.registration?.action === PurchaserProcessAction.invited
                        ? 'Invited' : 'Not Registered' }
                  date={latestActions.registration?.date}
                />
                <PurchaserStatusPanel
                  icon={latestActions.form1?.action === PurchaserProcessAction.viewed ? 'check_circle' : 'pending' }
                  title={'Accessed Form 1'}
                  date={latestActions.form1?.action === PurchaserProcessAction.viewed ? latestActions.form1?.date : ''}
                />
                <PurchaserStatusPanel
                  icon={latestActions.exampleContract?.action === PurchaserProcessAction.viewed ? 'check_circle' : 'pending'}
                  title={'Accessed Template Contract'}
                  date={latestActions.exampleContract?.action === PurchaserProcessAction.viewed ? latestActions.exampleContract?.date : ''}
                />
                {[SettingsOfferType.contract, SettingsOfferType.offer].includes(latestActions.offerDocument?.type??SettingsOfferType.none) && latestActions.offerDocument?.action !== PurchaserProcessAction.disabled &&
                <PurchaserStatusPanel
                  icon={latestActions.offerDocument?.action === PurchaserProcessAction.created ? 'check_circle' : 'pending'}
                  title={latestActions.offerDocument?.type === SettingsOfferType.offer ? 'Drafted Offer' : latestActions.offerDocument?.type === SettingsOfferType.contract ? 'Drafted Contract' : ''}
                  date={latestActions.offerDocument?.action === PurchaserProcessAction.created ? latestActions.offerDocument?.date : ''}
                />}
              </div>
            </div>
          </Alert>
        </Col>
      </Row>
    }
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={!!offerActionModal.visible} onClose={() => setOfferActionModal(p =>({ ...p, visible: false }))}/>}>
      <OfferActionModal
        visible={offerActionModal.visible}
        onClose={() => setOfferActionModal(p =>({ ...p, visible: false }))}
        onSubmit={handleOfferAction}
        actionType={offerActionModal.type}
        headline={headline}
        propertyMeta={propertyMeta}
      />
    </ErrorBoundary>
  </ContentTitler>;
}

const PurchaserStatusPanel = (props: {icon: string, title: string, date?: string | number}) => {
  return <div className={'d-flex me-5 align-items-center py-1 ps-2 pe-3 rounded-2'} style={{ backgroundColor: 'white', border: '1px #008bc1 solid' }}>
    <Icon name={props.icon} style={{ color: props.icon === 'check_circle' ? 'green' : 'gray', fontSize: '34px' }}></Icon>
    <div className={'d-flex flex-column ms-2'}>
      <div style={{ color: 'black' } }>{props.title}</div>
      <div className={'small text-secondary'}>{formatTimestamp(props.date||'', '', false)}</div>
    </div>
  </div>;
};

export function OfferManagementPage() {
  const { ydoc, transId, awareness } = useReactRouterData<RouterData>();
  return <SetupNetStateContext ydoc={ydoc} transactionRootKey={PropertyRootKey.Data}>
    <SetupNetStateWritingYjsDocContext
      ydoc={ydoc}
      awareness={awareness}
      docName={transId}
      transactionRootKey={PropertyRootKey.Data}
      transactionMetaRootKey={PropertyRootKey.Meta}
    >
      <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>
        <InnerPage />
      </RoomProvider>
    </SetupNetStateWritingYjsDocContext>
  </SetupNetStateContext>;
}

export function PurchaserProcess( { purchaserId, purchaserFor }: { purchaserId: string, purchaserFor: string | undefined } ) {
  const { value: offerManagement } = useLightweightTransaction<OfferMeta>({ bindToMetaKey: true, myPath: 'offerManagement' });
  const { data: settings, isLoading } = useGetPurchaserSettingsQuery({ portalId: offerManagement?.portalId, purchaserId });
  const [ updatePurchaserSettings] = useUpdatePurchaserSettingsMutation();

  const mappedSettings = {
    ...settings,
    ...settings?.offerType === SettingsOfferType.offer && { [PurchaserProcessStepType.SubmitLetterOfOffer]: true },
    ...settings?.offerType === SettingsOfferType.contract && { [PurchaserProcessStepType.SubmitContract]: true }
  };

  const processSteps = [PurchaserProcessStepType.Register, PurchaserProcessStepType.AccessR3, PurchaserProcessStepType.AccessBrochure, PurchaserProcessStepType.AccessForm1, PurchaserProcessStepType.AccessContract, PurchaserProcessStepType.SubmitLetterOfOffer, PurchaserProcessStepType.SubmitContract]
    ?.map(p => ({
      ...PurchaserProcessStep[p],
      enabled: p === PurchaserProcessStepType.AccessR3 || mappedSettings?.[p],
      stepType: p
    })) as ProcessStep[];

  const handleUpdatePurchaserSettings = (steps: ProcessStep[]) => {
    updatePurchaserSettings?.({
      portalId: offerManagement?.portalId,
      purchaserId: purchaserId,
      settings: mapStepsToSettings(steps)
    });
  };

  return isLoading
    ? <Spinner animation={'border'} style={{ width: '30px', height: '30px' }}/>
    : <ProcessEditor enableROR3={true} steps={processSteps} onUpdate={handleUpdatePurchaserSettings} processForText={purchaserFor||'purchaser'}/>;
}

export enum OfferActionModalType {
  withdraw = 'Withdraw',
  decline = 'Decline',
  accept = 'Accept',
  resubmit = 'Resubmit'
}

const pastTense: Record<OfferActionModalType, string> = {
  Accept: 'accepted',
  Decline: 'declined',
  Resubmit: 'resubmitted',
  Withdraw: 'withdrawn'
};

export const OfferActionModal = (props: { visible: boolean, onClose:()=>void, onSubmit:(email: { subject: string, message: string } | undefined)=>void, actionType: OfferActionModalType, headline: string, propertyMeta: MaterialisedPropertyMetaRecord }) => {
  const entityLogoUri = useEntityLogo(props.propertyMeta?.entity?.id);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const entityId = props.propertyMeta?.entity?.id;
  const { brand, tradeName } = useSelector(state=> state?.entityMeta?.[(entityId??-1)?.toString()] as EntitySettingsEntity | undefined) ?? {};
  const agentName = sessionInfo?.name;
  const actionPast = pastTense[props.actionType];
  const [notifyPurchaser, setNotifyPurchaser] = useState(true);
  const [message, setMessage] = useState<string>('');
  const [subject, setSubject] = useState<string>('');

  useEffect(() => {
    setSubject(`Offer ${actionPast}: ${props.headline}`);
    setMessage('');
  }, [props.actionType, props.visible]);

  return <Modal show={props.visible} onHide={props.onClose} dialogClassName='offer-action-dialog'>
    <Modal.Header closeButton className=''>
      <h3>{props.actionType} offer</h3>
    </Modal.Header>
    <Modal.Body>
      <div className={'mx-3'}>
        {props.actionType === OfferActionModalType.withdraw && <div>Outside of auction conditions, a purchaser can withdraw an offer before a contract is entered into by all parties.</div>}
        <Form.Check
          id={'notifyPurchasers'}
          type='switch'
          label='Customise message to purchaser'
          className={'me-3 mt-2 mb-3'}
          checked={notifyPurchaser}
          onChange={(e) => setNotifyPurchaser(e.target.checked)}
        />
      </div>

      {notifyPurchaser && <>
        <LimitedCharacterTextField label={'Email Subject'} value={subject} onChange={v => setSubject(v)} maxLength={100}/>
        <div className={'px-3'}>
          <div className="mb-4 email-preview-container d-flex justify-content-center">
            <div className="centerline">
              <div className="whitebox">
                <div><Image className='email-preview-logo' src={entityLogoUri}/></div>
                <div className="brand-box" style={{
                  backgroundColor: brand?.content?.backgroundColour || reaformsOrange,
                  color: brand?.content?.foregroundColour || 'white'
                }}>
                  <p className="mt-2">{agentName} from {tradeName} has {actionPast} your offer for {props.headline}.</p>
                </div>
                <div>
                  <p>{message?.split(/\n\n+/).filter(Predicate.isTruthy).map(para => {
                    return <p>{para.split(/\n/).filter(Predicate.isTruthy).map((line, idx, { length }) => idx < length - 1
                      ? <>{line}<br/></>
                      : line
                    )}</p>;
                  })}</p>
                  <p>If you have any questions about this, please contact:</p>
                  <p>
                    <b>{agentName}</b>
                    <br/>
                    <a href="#">{sessionInfo?.email}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomiseMessageStandalone config={{ content: message }} onConfigChange={value => setMessage(value.content || '')}/>
      </>}
    </Modal.Body>
    <Modal.Footer className=''>
      <Button onClick={props.onClose} variant='outline-secondary'>Cancel</Button>
      <Button onClick={()=>props.onSubmit(notifyPurchaser ? { subject, message } : undefined)} variant='primary'>{props.actionType}</Button>
    </Modal.Footer>
  </Modal>;
};
