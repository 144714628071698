import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { Spinner } from 'react-bootstrap';
import { buildYDoc } from '@property-folders/components/form-gen-util/buildYDoc';
import { v4 } from 'uuid';
import { YManager } from '@property-folders/common/offline/yManager';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import {
  FolderType,
  FormCode,
  FormSigningState,
  MaterialisedPropertyData,
  PartyType,
  TransactionMetaData
} from '@property-folders/contract';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { useSelector } from 'react-redux';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { UserPreferencesRootKey } from '@property-folders/contract/yjs-schema/user-preferences';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';
import { uuidv4 } from 'lib0/random';
import { mapPartyType } from '@property-folders/services/subscription-signing-event/mapPartyType';
import { canonicalisers, companyTradingAs } from '@property-folders/common/util/formatting';

export function GenerateSubscriptionFolderSigningSession() {
  const navigate = useNavigate();
  const { documentId: documentIdRaw } = useParams();
  const documentId = Number(documentIdRaw);
  const { instance: yManagerInstance } = useContext(YManagerContext);
  const [error, setError] = useState('');
  const getCurrentUserPrefs = useCallback(() => yManagerInstance?.getUserPrefs()?.doc.getMap(UserPreferencesRootKey.Main).toJSON(), [yManagerInstance]);
  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const { ydoc, localProvider, docName: ydocId, propertyFormId } = useMemo(() => {
    const newUUID = v4();
    const propertyFormId = v4();
    const result = buildYDoc(newUUID, true);
    YManager.instance().markUserViewing(newUUID, true);
    return { ...result, propertyFormId };
  }, []);

  const onError = (e?: any) => {
    if (e) {
      console.error(e);
    }

    setError('An error occurred generating the signing session.');
  };

  useEffect(() => {
    if (!sessionInfo || !ydoc || !localProvider || !documentId) {
      return;
    }

    setError('');
    const ac = new AbortController();
    AjaxPhp.getDocumentMeta({ documentId, signal: ac.signal }).then(meta => {
      if (!meta) {
        return;
      }

      const { formId, entityId, signatures, name, formName } = meta;
      const documentName = name;
      const formCode = FormCode.UploadedDocument;

      localProvider.whenSynced.then(() => {
        const localEntity = memberEntities && entityId ? memberEntities?.[entityId] : undefined;

        if (!localEntity || !getCurrentUserPrefs || !memberEntities) {
          return;
        }

        applyMigrationsV2_1<TransactionMetaData>({
          doc: ydoc,
          docKey: PropertyRootKey.Meta.toString(),
          typeName: 'Property',
          migrations: [
            {
              name: 'initialise metadata',
              fn: state => {
                const now = new Date();
                state.folderType = FolderType.Document;
                state.createdUtc = now.toISOString();

                // clearly this is not necessary, but typescript insists and I'd rather this
                // than a cast as any or something silly
                if (state.folderType === FolderType.Document) {
                  state.documentId = documentId;
                }

                state.entity = {
                  name: localEntity.name,
                  id: localEntity.entityId
                };

                state.creator = {
                  name: sessionInfo.name,
                  id: sessionInfo.agentId,
                  timestamp: now.getTime()
                };

                if (
                  !state.formStates
                  || !state.formStates[formCode]
                  || !state.formStates[formCode].instances?.find(i => i.id === propertyFormId)
                  || !state.formStates[formCode].instances?.find(i => i.id === propertyFormId)?.signing
                ) {
                  state.formStates = {};
                  state.formStates[formCode] = {
                    instances: [{
                      id: propertyFormId,
                      formCode: formCode as any,
                      modified: Date.now(),
                      created: Date.now(),
                      subscription: {
                        documentId,
                        fileName: formName,
                        formId
                      },
                      signing: {
                        parties: [],
                        state: FormSigningState.Configuring
                      }
                    }]
                  };

                  state.createdUtc = now.toISOString();
                }
              }
            },
            {
              name: 'set property id',
              docKey: PropertyRootKey.Data.toString(),
              fn: (state: any) => {
                const currentSalesperson = localEntity?.salespeople?.find(s => s.id === sessionInfo.agentId);
                const agent = {
                  id: sessionInfo.agentUuid,
                  phone: (canonicalisers.phone(currentSalesperson?.phone || '').canonical as string) || '',
                  email: (canonicalisers.email(sessionInfo.email || '').canonical as string) || '',
                  name: sessionInfo.name,
                  linkedSalespersonId: sessionInfo.agentId
                };

                (state as MaterialisedPropertyData).id = ydocId;
                (state as MaterialisedPropertyData).headline = documentName;
                (state as MaterialisedPropertyData).agent = [{
                  company: companyTradingAs(localEntity.name, localEntity.tradeName),
                  address: localEntity.address1,
                  phone: localEntity.phone,
                  email: localEntity.email,
                  abn: (canonicalisers.abnacn(localEntity.abn ?? '').canonical as string) ?? '',
                  rla: localEntity.rla ?? '',
                  linkedEntityId: entityId,
                  profileName: localEntity.profileName,
                  salesp: [agent],
                  id: v4()
                }];
                (state as MaterialisedPropertyData).otherContacts = signatures.map((s, partyIndex) => {
                  const category = mapPartyType(s);
                  if (s.startsWith('agent')) {
                    return {
                      id: uuidv4(),
                      fullLegalName: agent.name,
                      email1: agent.email,
                      phone1: agent.phone,
                      partyType: PartyType.Individual,
                      overridePartyCategory: category,
                      originalType: s
                    };
                  }

                  return {
                    id: uuidv4(),
                    fullLegalName: s,
                    partyType: PartyType.Individual,
                    overridePartyCategory: category,
                    originalType: s
                  };
                });
              }
            }
          ]
        });

        AjaxPhp.setDocumentPropertyFolder({
          documentId,
          signal: ac.signal,
          propertyFolderId: ydocId,
          propertyFormId
        }).then(() => {
          navigate(`/subscription-folder/${ydocId}/document/${propertyFormId}`);
        }).catch(onError);
      });
    }).catch(onError);

    return () => {
      ac.abort();
    };
  }, [localProvider, ydoc, !!sessionInfo, Object.keys(memberEntities ?? {}).length]);

  return <div className={'d-flex flex-row justify-content-center mt-5'}>
    <h2 className={'me-4'}>{error || 'Generating signing session...'}</h2>
    {!error && <Spinner animation={'border'} style={{ width: '2.5rem', height: '2.5rem' }} />}
  </div>;
}
