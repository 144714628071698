import { Alert, Button, Form, Image, InputGroup } from 'react-bootstrap';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { generateHeadlineFromMaterialisedData, materialisePropertyData, materialisePropertyMetadata } from '@property-folders/common/yjs-schema/property';
import { useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { GetPurchaserPortalResult, multiRepAuthority } from '@property-folders/contract';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import './ProspectivePurchasers.scss';
import { PartyAuthorityInput } from '@property-folders/components/dragged-components/form/PartyAuthorityInput';
import { purchaserTypeOptions } from '@property-folders/common/util/pdfgen/constants';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import '@property-folders/components/dragged-components/Form.scss';
import '@property-folders/components/dragged-components/Wizard/Wizard.scss';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { CustomiseMessageStandalone, LimitedCharacterTextField } from '@property-folders/components/dragged-components/signing/CustomiseMessage';
import { reaformsOrange } from '@property-folders/common/visual';
import '@property-folders/components/dragged-components/signing/SigningPartyGeneralConfiguration.scss';
import { useEntityLogo } from '@property-folders/components/hooks/useEntityLogo';
import { useSelector } from 'react-redux';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Predicate } from '@property-folders/common/predicate';
import {
  YManagerContext
} from '@property-folders/components/context/YManagerContext';
import { useForm } from '@property-folders/components/hooks/useForm';
import { PurchaserPortalData, PurchaserPortalRootKey } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { FormContext } from '~/../../components/context/FormContext';
import { v4 } from 'uuid';
import { BasicYjsContext } from '~/../../components/context/basic-yjs-context';
import { truncate } from 'lodash';
import { PurchaserApi } from '@property-folders/common/client-api/purchaserApi';
import { useGetPurchaserPortalQuery } from '@property-folders/common/redux-reducers/restApiSlice';
import { RouterData } from '~/App';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { purchaserPortalRoot } from '@property-folders/contract/yjs-schema/model/field';
import { buildYDoc } from '@property-folders/components/form-gen-util/buildYDoc';
import { materialisePurchaserPortal } from '@property-folders/common/yjs-schema/purchaser-portal';
import { useNavigate } from 'react-router-dom';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import '@property-folders/components/display/EmailPreview.scss';
import { getDataModelPrimaryContactDetails } from '@property-folders/common/util/dataExtract';
import { useOnline } from '@property-folders/components/hooks/useOnline';

function PurchaserYdocPage({ portal } : { portal: GetPurchaserPortalResult }) {
  const { ydoc, docName, transactionRootKey, formName } = useForm();
  const { value: root } = useTransactionField<PurchaserPortalData>({ myPath: '' });
  const {
    binder
  } = useImmerYjs<PurchaserPortalData>(ydoc, PurchaserPortalRootKey.Main);

  // initialise ydoc
  useEffect(() => {
    if (!root) return;
    if (!binder) return;
    if (root.id) return;
    if (!docName) return;

    const primaryPurchaserId = v4();
    binder.update(state => {
      state.id = docName;
      state.portalId = portal.portalId;
      state.purchasers = [{
        id: primaryPurchaserId
      }];
      state.primaryPurchaser = primaryPurchaserId;
      state.settings = portal.settings;
    });
  }, [!!root, !!binder, !!docName]);

  return <WizardStepPage name='Purchaser' label='Purchaser' icon=''>
    <PartyAuthorityInput
      primaryId={root.primaryPurchaser}
      primaryIdAbsPath={'primaryPurchaser'}
      thisLevel={1}
      hideOnTitleField={true}
      partyLabel='Purchaser'
      hideDelete={true}
      typeOptions={purchaserTypeOptions}
      myPath={`purchasers.[${root.primaryPurchaser}]`}
      purchaserPortalRegistration={false}
      hideRootPrimaryContactCheckbox={true}
      gnaf={portal.gnaf}
    />
  </WizardStepPage>;
}

type UserRegistrationDetails = {
  portalUserId: string,
  ydocId: string
};

export function ProspectivePurchasersAddPage() {
  const navigate = useNavigate();
  const { ydoc, transId } = useReactRouterData<RouterData>();
  const { instance: yManager } = useContext(YManagerContext);
  const propertyData = materialisePropertyData(ydoc);
  const propertyMeta = materialisePropertyMetadata(ydoc);
  const headline = generateHeadlineFromMaterialisedData(propertyData);
  const routerData = useReactRouterData<RouterData>();

  const isOnline = useOnline();

  const [message, setMessage] = useState<string>('');
  const [subject, setSubject] = useState<string>(truncate(`Purchaser portal Invitation: ${headline}`, { length: 100, omission: '...' }));
  const [shouldInvite, setShouldInvite] = useState(true);

  const entityLogoUri = useEntityLogo(propertyMeta?.entity?.id);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const entityId = propertyMeta?.entity?.id;
  const { brand, tradeName } = useSelector(state=> state?.entityMeta?.[(entityId??-1)?.toString()] as EntitySettingsEntity | undefined) ?? {};
  const agentName = sessionInfo?.name;
  const { data: portal } = useGetPurchaserPortalQuery(propertyMeta?.offerManagement?.portalId);
  const [ addDisabled, setAddDisabled ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  const { ydoc: tempDoc, localProvider: tempLocalProvider, docName: tempDocName } = useMemo(() => {
    if (!yManager) {
      return { ydoc: undefined, localProvider: undefined, docName: undefined };
    }
    const tempDocName = v4();
    const result = buildYDoc(tempDocName, true, yManager);
    yManager.markUserViewing(tempDocName, true);
    return result;
  }, [!!yManager]);
  const focusErrList = useSelector<any, string[] | undefined>((state: any) => state?.validation?.focusErrList?.[tempDocName ?? '']?.[PurchaserPortalRootKey.Main]?.[newPurchaserFormName]);

  const tempPropData = tempDoc && materialisePurchaserPortal(tempDoc);

  const { subPrimary } = getDataModelPrimaryContactDetails(tempPropData?.purchasers, tempPropData?.primaryPurchaser);

  useEffect(() => {
    if (!tempDoc) return;
    if (!tempDocName) return;
    if (!yManager) return;

    return () => {
      yManager.destroyEntry(tempDocName);
    };
  }, [!!tempDoc, !!yManager, tempDocName]);

  const handleAddClick = async () => {
    if (!tempDoc) return;
    if (!propertyMeta.offerManagement?.portalId) return;

    const initialData = materialisePurchaserPortal(tempDoc);
    if (!initialData) return;

    try {
      await PurchaserApi.createPurchaserPortalUser({
        propertyId: transId,
        portalId: propertyMeta.offerManagement.portalId,
        body: {
          initialData,
          // why wouldn't they be invited always?
          invite: shouldInvite
            ? { subject, message }
            : undefined
        }
      });

      navigate(-1);
    } catch (err: unknown) {
      if (err.errorCode === 409) {
        setErrorMessage(err.errorMessage);
        return;
      }
      console.error(JSON.parse(JSON.stringify(err)), typeof err, err.errorCode, err.errorMessage);
      setErrorMessage('Unexpected error, please try again.');
    }
  };

  const breadcrumbs = useMemo(() => [
    { label: 'Properties', href: '/properties/' },
    { label: headline, href: `/properties/${LinkBuilder.seoFriendlySlug(transId, headline)}` },
    { label: 'Add Prospective Purchaser' }
  ], [headline]);

  const afterTitle = <InputGroup className='gap-2 flex-row-reverse w-auto'>
    <Button onClick={()=> navigate(-1)} variant={'outline-secondary'}>Cancel</Button>
  </InputGroup>;

  const shouldInviteSwitch = <Form.Check
    id='shouldInvite'
    type="switch"
    checked={shouldInvite}
    onChange={e=> setShouldInvite(e.target.checked)}
    label="Invite to Purchaser portal?"
    // note: we don't currently support NOT inviting them. that's a future change
    disabled={true}
  />;

  return <ContentTitler title={'Invite Prospective Purchaser'} breadcrumbs={breadcrumbs} afterTitle={afterTitle}>
    <div style={{ overflow: 'auto', height: 'calc(100% - 87px)' }}>
      {errorMessage && <Alert variant='danger' dismissible={true} onClose={() => setErrorMessage('')}>{errorMessage}</Alert>}
      {isOnline
        ? <><form autoComplete='off' noValidate style={{ maxWidth: '800px', marginInline: 'auto' }}>
          {tempDoc && tempDocName && <BasicYjsContext yDoc={tempDoc} yDocId={tempDocName} rootKey={PurchaserPortalRootKey.Main}>
            <FormContext.Provider value={registrationFormContext}>
              {!!portal && <PurchaserYdocPage portal={portal} />}
            </FormContext.Provider>
          </BasicYjsContext>}

          <WizardStepPage name='Message' label='Message' icon='' headerContent={shouldInviteSwitch}>
            {shouldInvite && subPrimary.phone && !subPrimary.email && <div className='p-4'>
            A phone number has been provided for the primary contact but an email address was not. An invitation link will be sent via SMS. Message customisation is only available for Email invitations.
            </div>}
            {shouldInvite && !(subPrimary.phone && !subPrimary.email) && <><LimitedCharacterTextField label={'Email Subject'} value={subject} onChange={v => setSubject(v)} maxLength={100}/>
              <div className={'px-3'}>
                <div className="mb-4 email-preview-container d-flex justify-content-center">
                  <div className="centerline">
                    <div className="whitebox">
                      <div><Image className='email-preview-logo'  src={entityLogoUri}/></div>
                      <div className="brand-box" style={{
                        backgroundColor: brand?.content?.backgroundColour || reaformsOrange,
                        color: brand?.content?.foregroundColour || 'white'
                      }}>
                        <p className="mt-2">{agentName} from {tradeName} has invited you to the Purchaser portal
                      for {headline}.</p>
                        <div className="d-flex justify-content-center">
                          <div className="fake-button" style={{
                            backgroundColor: brand?.button?.backgroundColour || 'white',
                            color: brand?.button?.foregroundColour || reaformsOrange
                          }}>VIEW PURCHASER PORTAL
                          </div>
                        </div>
                      </div>
                      <div>
                        <p className="preview-user-message">
                      In the Purchaser portal you are able to:
                          <ul style={{ marginTop: 2 }}>
                            <li>Prepare and view documents for this transaction</li>
                            <li>Invite other parties to the documents</li>
                            <li>Sign and submit the documents electronically</li>
                            <li>Manage documents you have previously submitted</li>
                          </ul>
                        </p>
                        <p>{message?.split(/\n\n+/).filter(Predicate.isTruthy).map(para => {
                          return <p>{para.split(/\n/).filter(Predicate.isTruthy).map((line, idx, { length }) => idx < length - 1
                            ? <>{line}<br/></>
                            : line
                          )}</p>;
                        })}</p>
                        <p>
                          <b>{agentName}</b>
                          <br/>
                          <a href="#">{sessionInfo?.email}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CustomiseMessageStandalone config={{ content: message }} onConfigChange={value => setMessage(value.content || '')}/>
            </>}
          </WizardStepPage>
          <div className={'d-flex align-items-end flex-column me-3 px-2 mb-4'}>
            <AsyncButton variant={'primary'} onClick={handleAddClick} disabled={!!focusErrList?.length}>Invite</AsyncButton>
          </div>
        </form>
        </>
        :<div className='d-flex justify-content-center mt-4 fs-4 w-100'><div>Not currently possible to invite purchasers while offline</div></div>}
    </div>
  </ContentTitler>;
}

const newPurchaserFormName = 'newPurchaser';
export const registrationFormContext: FormContextType = {
  formName: newPurchaserFormName,
  formId: '',
  // note: all the isPrimaryContact are to try and restrict this validation to just the primary purchaser
  formRules: {
    purchasers: {
      _children: {
        authority: {
          _requiredIf: { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser']  }
        },
        personName1: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser']  },
              { evaluationFunction: 'isRepresentative', field: ['..'] }
            ],
            modeOrTrueAndFalse: false
          }
        },
        email1: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser']  },
              { oneOfExpectedValue: multiRepAuthority, field: ['..', 'authority'] },
              { evaluationFunction: 'primarySubcontactIsFirstPerson', field: ['..'] },
              { empty: true, field: ['..', 'phone1'] }
            ],
            modeOrTrueAndFalse: false
          }
        },
        phone1: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser']  },
              { oneOfExpectedValue: multiRepAuthority, field: ['..', 'authority'] },
              { evaluationFunction: 'primarySubcontactIsFirstPerson', field: ['..'] },
              { empty: true, field: ['..', 'email1'] }
            ],
            modeOrTrueAndFalse: false
          }
        },
        legalRepresentatives: {
          _children: {
            name: { _required: true },
            phone: { _requiredIf: {
              expectations: [
                { matchesValueAt: ['..','..','..', '..', '..', 'primaryPurchaser'], field: ['..', '..', '..','id'] },
                { oneOfExpectedValue: multiRepAuthority, field: ['..', '..', '..', 'authority'] },
                { matchesValueAt: ['..', '..', '..', 'primarySubcontactId'], field: ['..', 'id'] },
                { empty: true, field: ['..', 'email'] }
              ],
              modeOrTrueAndFalse: false
            } },
            email: { _requiredIf: {
              expectations: [
                { matchesValueAt: ['..','..','..', '..', '..', 'primaryPurchaser'], field: ['..', '..', '..','id'] },
                { oneOfExpectedValue: multiRepAuthority, field: ['..', '..', '..', 'authority'] },
                { matchesValueAt: ['..', '..', '..', 'primarySubcontactId'], field: ['..', 'id'] },
                { empty: true, field: ['..', 'phone'] }
              ],
              modeOrTrueAndFalse: false
            } }
          }
        },
        fullLegalName: {
          _requiredIf: { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser']  }
        },
        partyType: {
          _requiredIf: { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser']  }
        },
        personName2: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser']  },
              { evaluationFunction: 'isRepresentative', field: ['..'] },
              { evaluationFunction: 'isDualPartyType', field: ['..'] }
            ],
            modeOrTrueAndFalse: false
          }
        },
        email2: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser']  },
              { evaluationFunction: 'primarySubcontactIsSecondPerson', field: ['..'] },
              { empty: true, field: ['..', 'phone2'] }
            ],
            modeOrTrueAndFalse: false
          }
        },
        phone2: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser']  },
              { evaluationFunction: 'primarySubcontactIsSecondPerson', field: ['..'] },
              { empty: true, field: ['..', 'email2'] }
            ],
            modeOrTrueAndFalse: false
          }
        }
      },
      _minimum: 1,
      _required: true
    }
  },
  transactionRules: purchaserPortalRoot,
  fieldGroups: {
    'purchasers.[].partyType': 'partyResetActivity',
    'purchasers.[].authority': 'partyResetActivity',
    'purchasers.[].inTrust': 'partyResetActivity',
    'purchasers.[].registeredOnTitle': 'partyResetActivity'
  },
  metaRules: {
    _type: 'Map'
  }
};
