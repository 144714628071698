import { AuthApi } from '@property-folders/common/client-api/auth';
import { useContext, useEffect, useState } from 'react';
import { Alert, FormCheck, Spinner } from 'react-bootstrap';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { AjaxPhp, GaGetFeatureFlagsResponseData } from '@property-folders/common/util/ajaxPhp';

export function GlobalAdminOnly() {
  const isGa = Boolean(AuthApi.useGetAgentSessionInfo()?.data?.isGlobalAdmin);
  return isGa
    ? <GlobalAdminOnlyInner/>
    : <></>;
}

function GlobalAdminOnlyInner() {
  const { entityPhpInfo, entityPhpInfoStatus } = useContext(EntitySettingsContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<undefined | GaGetFeatureFlagsResponseData>(undefined);

  useEffect(() => {
    if (!entityPhpInfo) return;
    if (entityPhpInfo.entityId < 1) return;

    const ac = new AbortController();
    AjaxPhp.gaGetFeatureFlags({
      entityId: entityPhpInfo.entityId,
      signal: ac.signal
    })
      .then(data => {
        if (ac.signal.aborted) return;
        if (!data) return;
        if (!data.success) return;
        setData(data.data);
      })
      .catch(err => {
        if (ac.signal.aborted) return;
        console.log(err);
      })
      .finally(() => {
        if (ac.signal.aborted) return;
        setLoading(false);
      });

    return () => {
      ac.abort();
    };
  }, [entityPhpInfo?.entityId]);

  return <div>
    <h1>Feature Flags</h1>
    {loading || entityPhpInfoStatus === 'loading'
      ? <div className='d-flex flex-row justify-content-center align-items-center'>
        <Spinner animation='border'/>
      </div>
      : !loading && data && entityPhpInfo?.entityId
        ? <div>
          <Alert variant='warning' className='me-3'>
            <p>Note: it can take a small delay (~10s) for the changes here to be reflected in the entity yDoc.</p>
            <p className='mb-0'>e.g. If you enable/disable Property Folders, it will take some time before the
              navigation bar updates.</p>
          </Alert>
          <FlagRow
            entityId={entityPhpInfo.entityId}
            name={'UseNewPropertyTransactions'}
            label={'Property Folders Enabled'}
            defaultValue={data.UseNewPropertyTransactions}
          />
          <FlagRow
            entityId={entityPhpInfo.entityId}
            name={'UseNewFormsCreated'}
            label={'New Forms Created Enabled'}
            defaultValue={data.UseNewFormsCreated}
          />
          <FlagRow
            entityId={entityPhpInfo.entityId}
            name={'SigningPortalEnabled'}
            label={'Signing Portal Enabled'}
            defaultValue={data.SigningPortalEnabled}
          />
          <FlagRow
            entityId={entityPhpInfo.entityId}
            name={'UseContractors'}
            label={'Use Contractors'}
            defaultValue={data.UseContractors}
          />
        </div>
        : <div>Failed to load</div>}
  </div>;
}

function FlagRow({
  entityId,
  name,
  label,
  defaultValue
}: {
  entityId: number,
  name: string,
  label: string,
  defaultValue: boolean
}) {
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  return <div className='d-flex flex-row align-items-center gap-3 ps-1'>
    <FormCheck
      id={name}
      name={name}
      label={label}
      checked={value}
      disabled={loading}
      onChange={() => {
        setLoading(true);
        AjaxPhp.gaSetFeatureFlag({ entityId, name, value: !value })
          .then(data => {
            if (!data?.success) return;
            setValue(data.value);
          })
          .finally(() => {
            setLoading(false);
            entityPhpInfo?.reload();
          });
      }}
    />
    {loading && <Spinner size='sm' animation='border'/>}
  </div>;
}
